

body{
    margin: 0;
    padding: 0;
    background-color: #ECEFF1;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;

}

#r_main{
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
}

#first_l{
    background-size: cover;
    height: 90vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
   display: flex;
   flex-direction: column;
   justify-content: center;
   border-radius: 0 0 15px 15px;
   
}


#first_l_c{
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 600px;
    margin-left: 24px;
    justify-content: center;
}

#first_l_c h2{
    text-decoration: line-through;
    color: white;
    font-size: 1.2em;
    margin: 4px;
   
}

#pric_c{
    display: flex;
    flex-direction: row;

}

#fgn{
    font-family: 'Roboto', sans-serif;
    color: white;
    font-weight: bold;
    font-size: 3.5em;
    margin-bottom: 10px;
   

}

#f_p_b{

    background-color: white;
    color: black;
    font-family: 'Roboto', sans-serif;
    width: 150px;
    height: 70px;
    border-radius: 17px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;

}


#sec {
    display: flex;
    flex-direction: row;
    height: 350px;
}

#re_box{
    width: 250px;
    height:360px;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 10px 10px;
    background-color: white;
    margin-left: 100px;
    margin-top: 16px;
}

#re_box img{

    border-radius: 10px;
    width: inherit;
    height: 150px;
    
}

#s_n_r{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
}

#re_box h2 {
    margin: 0px;
    margin-left: 6px;

}
#s_n_r img{
    width: 20px;
    height: 20px;
}
#s_n_r div {
    display: flex;
    flex-direction: row;
    margin-right: 4px;
}
#re_box span {
    
    margin-left: 2px;
}
#re_box h3 {
    text-align: center;
    color: black;
    margin: 2px;

}

#re_box h5 {
    text-align: center;
    color: black;
    margin: 2px;

}

#re_box button {
    background-color: black;
    color: white;
    font-family: 'Roboto', sans-serif;
    width: 240px;
    height: 50px;
    border-radius: 17px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
    
}


@media(max-width: 568px) {
    #sec {
        display: flex;
        flex-direction: row;
        height: auto;
        margin: 10px;
        overflow-y: auto;
    
       
    
    }
 
    #first_l{
        background-size: cover;
        height: 300px;
        width: 400px;
        background-position: center;
        background-repeat: no-repeat;
       display: flex;
       flex-direction: column;
       justify-content: center;
       border-radius: 0 0 15px 15px;
       
    }


    #first_l_c{
        display: flex;
        flex-direction: column;
        width: 200px;
        height: 600px;
        margin-left: 24px;
        justify-content: center;
    }

    #fgn{
        font-size: 1.5em;
    }
    
    #first_l_c h3{
        color: white;
        font-size: 0.5em;
        margin: 4px;
       
    }

    #f_p_b{

        background-color: white;
        color: black;
        font-family: 'Roboto', sans-serif;
        width: 100px;
        height: 50px;
        border-radius: 17px;
        border: none;
        font-weight: bold;
        cursor: pointer;
        margin-top: 15px;
        font-size: 0.7em;
    
    }

    #re_box{
        margin-left: 30px;
    }
}